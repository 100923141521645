<script>
	import Button from "$components/ui/button/button.svelte";
	import * as m from "$m";
	import { Moon, Sun } from "lucide-svelte";
	import { mode, toggleMode } from "mode-watcher";
</script>

<nav class="flex justify-between items-center m-4">
	<ul>
		<li>
			<a href="/" class="flex gap-2"
				><h1
					class="text-3xl font-bold tracking-tighter sm:text-5xl xl:text-6xl/none"
				>
					<strong>Frakty</strong>
				</h1>
				<h4 class="self-end">(beta)</h4></a
			>
		</li>
	</ul>
	<!-- <ul>
		<li>
			<a href="/" class={$page.url.pathname === "/" ? "font-bold" : ""}
				>Home</a
			>
		</li>
	</ul> -->
	<ul class="flex gap-4 items-center">
		<li>
			<Button on:click={toggleMode}>
				{#if $mode === "light"}
					<Moon></Moon>
				{:else}
					<Sun></Sun>
				{/if}
			</Button>
		</li>
		<li>
			<Button href="/login" variant="secondary">{m.login()}</Button>
		</li>
		<li>
			<Button href="/register">{m.get_started()}</Button>
		</li>
	</ul>
</nav>
